import { Component, OnInit } from '@angular/core';
import { MenuComponent } from '../menu/menu.component';
import { ContenidoService } from '../contenido.service';

@Component({
  selector: 'app-ry-accesories',
  templateUrl: './ry-accesories.component.html',
  styleUrls: ['./ry-accesories.component.css',
  '../../assets/css/bootstrap.min.css',
    '../../assets/scss/styles.scss', 
    '../../assets/scss/royal.scss', 
    '../../assets/scss/fonts.scss',
    './ry-accesories.component.css',
    '../../assets/ema/main.scss',
    ]
})
export class RyAccesoriesComponent implements OnInit {
  public contenido = this.contenidoService.getContenido("ry-accesories");
  constructor(private contenidoService: ContenidoService) {}

  ngOnInit() {
  }

}
