import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import * as _ from 'lodash';
import * as glob from './contenidoGlobal';

@Injectable({
  providedIn: 'root'
})
export class ContenidoService {
  
  constructor() {
  }
  getContenido(link:string){
    try {
      var aux = [];
      if(!_.isUndefined(_.find(glob.contenido,function(o){return o.link == link})))
        aux = _.find(glob.contenido,function(o){return o.link == link}).variables;
      else{
        _.forEach(glob.contenido, function(o){
          if(!_.isUndefined(_.find(o.hijos,function(oo){return oo.link == link})))
            aux = _.find(o.hijos,function(oo){return oo.link == link}).variables;
        });
      }
      return aux; 
    } catch (error) {
      throw new Error(error);
    }
  }
}
