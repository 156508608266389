import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import * as glob from './contenidoGlobal';
import * as _ from 'lodash';
import { ContenidoService } from './contenido.service';
import { LoginService } from './login/services/login.service';

import { MaterialesService } from './materiales.service';
import { VideosService } from './videos.service';
import { NewsService } from './news.service';
import { TrainingService } from './training.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
  	'../assets/css/bootstrap.min.css',
	'../assets/scss/styles.scss',
	'../assets/scss/menu.scss',
	'../assets/scss/icomoon.scss',
	'../assets/css/fonts.css',
	'./app.component.css'
  ]
})
export class AppComponent implements OnInit {
	constructor(private trainingService: TrainingService, private newsService: NewsService, private videoService: VideosService, private materialesService: MaterialesService, public router: Router, private contenidoService: ContenidoService, private loginService: LoginService) {}

  	title = 'app';
  	showBusqueda = false;
  	contenidoDinamico = [];
  	showBuscador = this.loginService.isAuthenticated();
  	resultadoBusqueda = new Array<any>(); 
  	buscar = "";
  	contador = 1;
  	menuApp = [
  		{
  			texto: "Home",
  			link: "home"
  		},
  		{
  			texto: "General",
  			colapsar: false,
  			hijos: [
  				{
  					texto: "Documentos de Viaje",
  					link: "travel-documents"
  				},
				{
					texto: "Preparativos de Viaje",
					link: "travel-prepatation"
				},
				{
					texto: "Servicios a los Huépedes",
					link: "services-guests"
				},
				{
					texto: "Políticas Abordo",
					link: "policies-board"
				},
				// {
				// 	texto: "Espresso"
				// },
				{
					texto: "Políticas de Cancelación y Penalidades de reserva",
					link: "policies-cancelation"
				},
				{
					texto: "Embarque y Desembarque",
					link: "board"
				}
  			]
  		},
  		{
  			texto: "Noticias",
  			link: "news"
  		},
  		{
  			texto: "Royal Caribbean",
  			colapsar: false,
  			hijos: [
  				{
  					texto: "Información General de la Marca",
  					link: "royal-caribbean"
  				},
				{
					texto: "Destinos",
  					link: "ry-destination"
				},
				{
					texto: "Flota",
  					link: "ry-fleet"
				},
				{
					texto: "Complementos para el Crucero",
					link:"ry-accesories"
				},
				{
					texto: "Club de Lealtad",
					link: "ry-loyalty"
				},
				{
					texto: "Next Cruise",
					link: "ry-next-cruise"
				},
				{
					texto: "Media"
				},
				{
					texto: "Videos",
					link: "ry-video"
				},
  			]
  		},
  		{
  			texto: "Celebrity Cruises",
  			colapsar: false,
  			hijos: [
  				{
  					texto: "Información General de la Marca",
  					link: "celebrity-cruises"
  				},
				{
					texto: "Destinos",
					link: "cc-destination"
				},
				{
					texto: "Flota",
					link: "cc-fleet"
				},
				{
					texto: "Complementos para el Crucero",
					link: "cc-accesories"
				},
				{
					texto: "Club de Lealtad",
					link: "cc-loyalty"
				},
				{
					texto: "Future Cruise",
					link: "cc-future-cruise"
				},
				{
					texto: "Media"
				},
				{
					texto: "Videos",
					link: "cc-video"
				},
  			]
  		},
  		{
  			texto: "Azamara Club Cruises",
  			colapsar: false,
  			hijos: [
  				{
  					texto: "Información General de la Marca",
  					link: "azamara"
  				},
				{
					texto: "Destinos",
					link: "az-destination"
				},
				{
					texto: "Flota",
					link: "az-fleet"
				},
				{
					texto: "Complementos para el Crucero",
					link: "az-accesories"
				},
				{
					texto: "Club de Lealtad",
					link: "az-loyalty"
				},
				{
					texto: "Cruise Again",
					link: "az-next-cruise"
				},
				{
					texto: "Media"
				},
				{
					texto: "Videos",
					link: "az-video"
				},
  			]
  		},
  		{
  			texto: "Grupos",
  			link: "/grupos"
  		},
  		{
  			texto: "Mayoristas",
  			colapsar: false,
  			hijos: [
  				{
  					texto: "Usuario y Contraseña",
  					child: true
  				},
  				{
  					texto: "Comunicados",
  					child: true,
  					hijos: [
  						{
  							texto: "Finanzas"
  						},
						{
							texto: "Operaciones"
						},
						{
							texto: "Grupos"
						},
						{
							texto: "Marketing"
						},
						{
							texto: "Ventas"
						},
  					]
  				}
  			]
  		}
  	];

  	checkBuscador(){
  		setTimeout(() => {
  			if(this.contenidoDinamico.length == 0){
  				this.getMateriales('Azamara', 'az-media');
  				this.getMateriales('Celebrity', 'cc-media');
  				this.getMateriales('Royal', 'ry-media');
  				this.getVideos('Azamara', 'az-video');
  				this.getVideos('Celebrity', 'cc-video');
  				this.getVideos('Royal', 'ry-video');
  				this.newsService.getNews().then(news => {
  					var variables = [];
					_.forEach(news,function(o){
						variables.push([o.titulo]);
					})
					this.contenidoDinamico.push({texto: "Noticias",link: "news",variables: variables});
				}).catch(err => console.error(err));
				this.trainingService.getAllTrainings().then(trainings => {
					var variables = [];
					_.forEach(trainings,function(o){
						variables.push([o.titulo]);
					})
					this.contenidoDinamico.push({texto: "Training",link: "training",variables: variables});
				}).catch(err => {console.error(err);});
  			}
  			this.showBuscador = this.loginService.isAuthenticated();
  		});
  	}
  	getMateriales(marca: string, link: string){
  		this.materialesService.getMaterialesByMarc(marca).then(materiales => {
			var variables = [];
			_.forEach(materiales,function(o){
				variables.push([o.titulo]);
			})
			this.contenidoDinamico.push({texto: "Materiales",link: link,variables: variables});
		})
		.catch(err => console.error(err));
  	}
  	getVideos(marca: string, link: string){
  		this.videoService.getAllVideos(marca).then(videos => {
			var variables = [];
			_.forEach(videos,function(o){
				variables.push([o.titulo]);
			})
			this.contenidoDinamico.push({texto: "Videos",link: link,variables: variables});
		})
		.catch(err => {console.error(err);});
  	}
	menu = () => {
		$('.menu').on('click', (event) => {
      		$('.menu-top').toggleClass('menu-top-click');
      		$('.menu-middle').toggleClass('menu-middle-click');
      		$('.menu-bottom').toggleClass('menu-bottom-click');
      		event.preventDefault();
    	});
	}

	activarMenu = () => {
		$('.menu').click(() => {
			if (screen.width<768) {
				if (this.contador == 1){
					$('.menu-colaps').animate({
						left: '0%'
					});
					$( ".overlay" ).addClass( "show-ol" );
					$( "body" ).addClass( "body-fixed" );
					this.contador = 0;
				} else {
					this.contador = 1;
					$('.menu-colaps').animate({
						left: '104%'
					});
					$( ".overlay" ).removeClass( "show-ol" );
					$( "body" ).removeClass( "body-fixed" );
				};
	        } else if (screen.width<1025) {
				if (this.contador == 1){
					$('.menu-colaps').animate({
						left: '50%'
					});
					$( ".overlay" ).addClass( "show-ol" );
					$( "body" ).addClass( "body-fixed" );
					this.contador = 0;
				} else {
					this.contador = 1;
					$('.menu-colaps').animate({
						left: '104%'
					});
					$( ".overlay" ).removeClass( "show-ol" );
					$( "body" ).removeClass( "body-fixed" );
				};
			} else {
				if (this.contador == 1){
					$('.menu-colaps').animate({
						left: '70%'
					});
					$( ".overlay" ).addClass( "show-ol" );
					$( "body" ).addClass( "body-fixed" );
					this.contador = 0;
				} else {
					this.contador = 1;
					$('.menu-colaps').animate({
						left: '104%'
					});
					$( ".overlay" ).removeClass( "show-ol" );
					$( "body" ).removeClass( "body-fixed" );
				};
			};

		});
	}

	activarMenu2 = () => {
		if (screen.width<768) {
				if (this.contador == 1){
					$('.menu-colaps').animate({
						left: '0%'
					});
					$( ".overlay" ).addClass( "show-ol" );
					$( "body" ).addClass( "body-fixed" );
					this.contador = 0;
				} else {
					this.contador = 1;
					$('.menu-colaps').animate({
						left: '104%'
					});
					$( ".overlay" ).removeClass( "show-ol" );
					$( "body" ).removeClass( "body-fixed" );
				};
	    } else if (screen.width<1025) {
				if (this.contador == 1){
					$('.menu-colaps').animate({
						left: '50%'
					});
					$( ".overlay" ).addClass( "show-ol" );
					$( "body" ).addClass( "body-fixed" );
					this.contador = 0;
				} else {
					this.contador = 1;
					$('.menu-colaps').animate({
						left: '104%'
					});
					$( ".overlay" ).removeClass( "show-ol" );
					$( "body" ).removeClass( "body-fixed" );
				};
		} else {
				if (this.contador == 1){
					$('.menu-colaps').animate({
						left: '70%'
					});
					$( ".overlay" ).addClass( "show-ol" );
					$( "body" ).addClass( "body-fixed" );
					this.contador = 0;
				} else {
					this.contador = 1;
					$('.menu-colaps').animate({
						left: '104%'
					});
					$( ".overlay" ).removeClass( "show-ol" );
					$( "body" ).removeClass( "body-fixed" );
				};
		};
			$('.menu-top').toggleClass('menu-top-click');
      		$('.menu-middle').toggleClass('menu-middle-click');
      		$('.menu-bottom').toggleClass('menu-bottom-click');
      		event.preventDefault();
	}

	colapsar = (index, item, e, j) => {
		console.log(item);
		e.preventDefault();
		if(item.hasOwnProperty('link')){
			this.router.navigate([item.link]);
			this.esconderMenu();
			this.activarMenu2()
		}else{
			if(item.hasOwnProperty('child')){
				$($($($($(".father")[index]).children()[1]).children()[j]).children()[1]).slideToggle();
				if (!item.hasOwnProperty('hijos'))
					this.activarMenu2();
			}else{
				for (var i = 0; i < this.menuApp.length; i++) {
					if(i == index){
						$($($(".father")[i]).children()[1]).slideToggle();
					}else{
						$($($(".father")[i]).children()[1]).slideUp();
					}
				}
			}
		}

	}

	esconderMenu = () => {
		$('.children').slideUp();
		//this.activarMenu();
		//this.menu();
	}


	resetValues(){
		this.buscar = "";
		this.showBusqueda = false;
	}
	buscador = (buscar) => {
		if(buscar){
			var pBuscar = _.lowerCase(buscar);
			this.showBusqueda = true;
			var res = [];
			_.forEach(glob.contenido,function(o){
				if(!_.isUndefined(o.hijos)){
					_.forEach(o.hijos,function(oo){
						if(_.startsWith(_.lowerCase(oo.texto),pBuscar) || _.startsWith(_.lowerCase(oo.link),pBuscar))
							res.push({palabra:o.texto +" - "+ oo.texto,link:oo.link});
						_.forEach(oo.variables,function(v){
							var vv = _.lowerCase(v);
							if(vv.indexOf(pBuscar) != -1){
								res.push({palabra:v[0].substr(vv.indexOf(pBuscar),vv.length),link:oo.link});
							}
						});
					});
				}else{
					if(_.startsWith(_.lowerCase(o.texto),pBuscar) || _.startsWith(_.lowerCase(o.link),pBuscar))
						res.push({palabra:o.texto,link:o.link});
					_.forEach(o.variables,function(v){
						var vv = _.lowerCase(v);
						if(vv.indexOf(pBuscar) != -1){
							res.push({palabra:v[0].substr(vv.indexOf(pBuscar),vv.length),link:o.link});
						}
					});
				}
			});
			_.forEach(this.contenidoDinamico,function(o){
				_.forEach(o.variables,function(v){
					var vv = _.lowerCase(v);
					if(vv.indexOf(pBuscar) != -1){
						res.push({palabra:v[0].substr(vv.indexOf(pBuscar),vv.length),link:o.link});
					}
				});
			});
			if(res.length == 0)
				res.push({palabra:"Sin resultados",link:""});
			this.resultadoBusqueda = res;
		}
	}
	changeView = (item) =>{
		this.showBusqueda = false;
		this.buscar = "";
		if(item == "home")
			window.location.href = "/#/";
		else
			this.router.navigate([item]);
	}

	closeList = () => {
		this.showBusqueda = false;
	}
	/*
	uploadDocument(url) {
		var blob = null;
		var xhr = new XMLHttpRequest();
		xhr.open("GET", url, true); 
		xhr.responseType = "blob";//force the HTTP response, response-type header to be blob
		xhr.onload = function() 
		{
			// console.info(xhr.getResponseHeader('Content-Type'))//text/html; charset=UTF-8
		    blob = xhr.response;//xhr.response is now a blob object
		    let fileReader = new FileReader();
		    fileReader.onload = (e) => {
		      console.log(fileReader.result);
		    }
		    fileReader.readAsText(blob);
		}
		xhr.send();
	}*/
	cerrarBusqueda = () => {
		this.showBusqueda = false;
		this.buscar = "";
	}

	ngOnInit() {
		this.activarMenu();
		this.menu();
	}
}
