import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-royal-caribbean-group',
  templateUrl: './royal-caribbean-group.component.html',
  styleUrls: ['./royal-caribbean-group.component.scss']
})
export class RoyalCaribbeanGroupComponent implements OnInit, AfterViewInit{

  constructor() { }

  ngOnInit() {
  	
  }

  ngAfterViewInit(){
  	window.scrollTo(0, 0);
  }

}
