import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import * as _ from 'lodash';

import { MenuComponent } from '../menu/menu.component';
import { BannerService } from '../banner.service';
import { PromocionesService } from '../promociones.service';
import { SeccionNoticiaService } from '../seccion-noticia.service';
import { environment } from 'src/environments/environment';
import { ContenidoService } from '../contenido.service';


@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: [
  '../../assets/css/bootstrap.min.css',
  '../../assets/scss/styles.scss',
  '../../assets/scss/home.scss',
  '../../assets/scss/icomoon.scss',
  '../../assets/scss/fonts.scss',
  'principal.component.css',
  '../../assets/ema/main.scss',

  ]
})
export class PrincipalComponent implements OnInit {
  public contenido = this.contenidoService.getContenido("home");
	public promociones: any;
  private banners: any;
	private pdfs: any;
	public loading: boolean;
  public noticia: any = {
    leftUrl:'',
    rightUrl: '',
    buttonUrl: '',
    texto: '',
    typeLeft: '',
    typeRight: ''
  };
  showVideo = false;
  currentVideo = '';
  constructor(private contenidoService: ContenidoService, public router: Router, private bannerService: BannerService, private seccionNoticiaService: SeccionNoticiaService, private promocionesService: PromocionesService) {}
  title = 'app';
  contador = 1;
  menuApp = [
  {
    texto: "Home",
    link: "home"
  },
  {
    texto: "General",
    colapsar: false,
    hijos: [
    {
      texto: "Documentos de Viaje",
      link: "travel-documents"
    },
    {
      texto: "Preparativos de Viaje",
      link: "travel-prepatation"
    },
    {
      texto: "Servicios a los Huépedes",
      link: "services-guests"
    },
    {
      texto: "Políticas Abordo",
      link: "policies-board"
    },
    // {
      // 	texto: "Espresso"
      // },
      {
        texto: "Políticas de Cancelación y Penalidades de reserva",
        link: "policies-cancelation"
      },
      {
        texto: "Embarque y Desembarque",
        link: "board"
      }
      ]
    },
    {
      texto: "Noticias",
      link: "news"
    },
    {
      texto: "Royal Caribbean",
      colapsar: false,
      hijos: [
      {
        texto: "Información General de la Marca",
        link: "royal-caribbean"
      },
      {
        texto: "Destinos",
        link: "ry-destination"
      },
      {
        texto: "Flota",
        link: "ry-fleet"
      },
      {
        texto: "Complementos para el Crucero",
        link:"ry-accesories"
      },
      {
        texto: "Club de Lealtad",
        link: "ry-loyalty"
      },
      {
        texto: "Next Cruise",
        link: "ry-next-cruise"
      },
      {
        texto: "Media"
      },
      {
        texto: "Videos",
        link: "ry-video"
      },
      ]
    },
    {
      texto: "Celebrity Cruises",
      colapsar: false,
      hijos: [
      {
        texto: "Información General de la Marca",
        link: "celebrity-cruises"
      },
      {
        texto: "Destinos",
        link: "cc-destination"
      },
      {
        texto: "Flota",
        link: "cc-fleet"
      },
      {
        texto: "Complementos para el Crucero",
        link: "cc-accesories"
      },
      {
        texto: "Club de Lealtad",
        link: "cc-loyalty"
      },
      {
        texto: "Future Cruise",
        link: "cc-future-cruise"
      },
      {
        texto: "Media"
      },
      {
        texto: "Videos",
        link: "cc-video"
      },
      ]
    },
    {
      texto: "Azamara Club Cruises",
      colapsar: false,
      hijos: [
      {
        texto: "Información General de la Marca",
        link: "azamara"
      },
      {
        texto: "Destinos",
        link: "az-destination"
      },
      {
        texto: "Flota",
        link: "az-fleet"
      },
      {
        texto: "Complementos para el Crucero",
        link: "az-accesories"
      },
      {
        texto: "Club de Lealtad",
        link: "az-loyalty"
      },
      {
        texto: "Cruise Again",
        link: "az-next-cruise"
      },
      {
        texto: "Media"
      },
      {
        texto: "Videos",
        link: "az-video"
      },
      ]
    },
    {
      texto: "Grupos",
      link: "/grupos"
    },
    {
      texto: "Mayoristas",
      colapsar: false,
      hijos: [
      {
        texto: "Usuario y Contraseña",
        child: true
      },
      {
        texto: "Comunicados",
        child: true,
        hijos: [
        {
          texto: "Finanzas"
        },
        {
          texto: "Operaciones"
        },
        {
          texto: "Grupos"
        },
        {
          texto: "Marketing"
        },
        {
          texto: "Ventas"
        },
        ]
      }
      ]
    }
    ];

    imagenesHome = {
      logoAgentes: '../images/home/LOGO_AGENTES.png'
    }

    cambioRegistrar = () => {
      console.log("CAMBIO");
      this.router.navigate(['/user', 5]);
    }

    logout = (e) => {
      e.preventDefault();
      delete localStorage.jwt;
      delete localStorage.perfil;
      this.router.navigate(["/"]);
    }

    ngOnInit(){
      console.log("principal.component.ts")

      this.promocionesService.getAllPromociones().subscribe(observable => {
        this.promociones = observable.map(snapshot => {
          let data = snapshot.payload.doc.data();
          let id = snapshot.payload.doc.id;
          let marcaPath = `${environment.firestorePath}/marcas/${id}`;
          return {...data, marcaPath}
        })
      })

      this.loading = true;
      this.bannerService.getAllBanners()
      .then(banners => {
        console.info(banners)
        this.banners = _.sortBy(banners,["pos"]);
        this.pdfs = this.banners.filter(doc =>
          doc.marc != undefined
          )
        this.seccionNoticiaService.getAllNoticia()
        .then(noticia => {
          this.noticia = noticia[0];
          this.loading = false;
        })
        .catch(err => {
          console.error(err);
          this.loading = false;
        });
      })
      .catch(err => {
        console.error(err);
        this.loading = false;
      });


    }

    openVideo = (url) => {
       this.showVideo = true;
       let urlToSee = '//www.youtube.com/embed/'+this.getUrlIframe(url);
       setTimeout(function() {
         $('iframe').attr('src', urlToSee)
      }, 500);
    }; 
    getThumb = (url) =>{
      return '//img.youtube.com/vi/' + this.getUrlIframe(url) + '/0.jpg';
    }
    getUrlIframe = (url) => {
      var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      var match = url.match(regExp);

      if (match && match[2].length == 11) {
          return match[2];
      } else {
          return 'error';
      }
    }

    closeVideo = () =>{
      this.showVideo = false;
    };
  }
