import { Component, OnInit } from '@angular/core';
import { MaterialesService } from '../materiales.service';
import { ContenidoService } from '../contenido.service';

@Component({
  selector: 'app-cc-media',
  templateUrl: './cc-media.component.html',
  styleUrls: ['./cc-media.component.css','../../assets/scss/styles.scss',
	'../../assets/scss/general-board.scss', 
    '../../assets/scss/fonts.scss',    
    '../../assets/ema/main.scss']
})
export class CcMediaComponent implements OnInit {
  public materiales;
  public contenido = this.contenidoService.getContenido("cc-media");
  constructor(private materialesService: MaterialesService, private contenidoService: ContenidoService) { }

  ngOnInit() {
    this.materialesService.getMaterialesByMarc('Celebrity')
      .then(materiales => {
        this.materiales = materiales;
      })
      .catch(err => console.error(err));
  } 

}
