import { Directive, Input, ElementRef } from '@angular/core';
import { AdminPdfService } from './admin-pdf.service';


@Directive({
  selector: '[administrableHref]'
})
export class AdminastrableHrefDirective {

  @Input('administrableHref') idPdf: string;

  constructor(private elementRef:ElementRef, private pdfService: AdminPdfService) { }

  ngOnInit() {
    this.pdfService.getPdfByName(this.idPdf).subscribe(snapshot => {
      let doc = snapshot.payload;
      if(doc.exists){
        let data = doc.data();
        this.elementRef.nativeElement.href = doc.data()['url']
      }
    });
  }

}
