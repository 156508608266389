import { Component, OnInit } from '@angular/core';
import { MenuComponent } from '../menu/menu.component';
import { ContenidoService } from '../contenido.service';

@Component({
  selector: 'app-royal-caribbean',
  templateUrl: './royal-caribbean.component.html',
  styleUrls: [
    '../../assets/css/bootstrap.min.css',
    '../../assets/scss/styles.scss', 
    '../../assets/scss/royal.scss', 
    '../../assets/scss/fonts.scss',
    './royal-caribbean.component.css',
    '../../assets/ema/main.scss',

  ]
})
export class RoyalCaribbeanComponent implements OnInit {
  public contenido = this.contenidoService.getContenido("royal-caribbean");
  constructor(private contenidoService: ContenidoService) {}

  ngOnInit() { }

}
