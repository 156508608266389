import { Component, OnInit } from '@angular/core';
import { MenuComponent } from '../menu/menu.component';
import { ContenidoService } from '../contenido.service';

@Component({
  selector: 'app-ry-fleet',
  templateUrl: './ry-fleet.component.html',
  styleUrls: [
    '../../assets/css/bootstrap.min.css',
    '../../assets/scss/styles.scss', 
    '../../assets/scss/royal.scss', 
    '../../assets/scss/fonts.scss',
    '../../assets/ema/main.scss',
    './ry-fleet.component.css']
})
export class RyFleetComponent implements OnInit {
  public contenido = this.contenidoService.getContenido("ry-fleet");
  constructor(private contenidoService: ContenidoService) {}

  ngOnInit() {
  }

}
