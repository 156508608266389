import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-azamara-group',
  templateUrl: './azamara-group.component.html',
  styleUrls: ['./azamara-group.component.css',
  '../../assets/cssDali/general.scss']
})
export class AzamaraGroupComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(){
  	window.scrollTo(0, 0);
  }

}
