import { Component, OnInit } from '@angular/core';
import { ContenidoService } from '../contenido.service';

@Component({
  selector: 'app-grupo',
  templateUrl: './grupo.component.html',
  styleUrls: [
    '../../assets/css/bootstrap.min.css',
    '../../assets/scss/styles.scss',
    '../../assets/scss/group.scss',
    '../../assets/scss/icomoon.scss',
    '../../assets/scss/fonts.scss',
    'grupo.component.css',
    '../../assets/ema/main.scss',

  ]
})
export class GrupoComponent implements OnInit {
  public contenido = this.contenidoService.getContenido("/grupos");
  constructor(private contenidoService: ContenidoService) {}

  ngOnInit() {
  }

}
