import { Component, OnInit } from '@angular/core';
import { ContenidoService } from '../contenido.service';

@Component({
  selector: 'app-cc-destination',
  templateUrl: './cc-destination.component.html',
  styleUrls: [
  '../../assets/css/bootstrap.min.css',
    '../../assets/scss/styles.scss', 
    '../../assets/scss/celebrity.scss', 
    '../../assets/scss/fonts.scss',
  './cc-destination.component.css',
  '../../assets/cssDali/general.scss'
  ]
})
export class CcDestinationComponent implements OnInit {
  public contenido = this.contenidoService.getContenido("cc-destination");
  constructor(private contenidoService: ContenidoService) {}

  ngOnInit() {
  }

}
