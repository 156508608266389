import { Component, OnInit } from '@angular/core';
import { MenuComponent } from '../menu/menu.component';
import { NewsService } from '../news.service';
import { ContenidoService } from '../contenido.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: [  
    '../../assets/scss/styles.scss',
	  '../../assets/scss/general-board.scss', 
    '../../assets/scss/fonts.scss',
    './news.component.css',
    '../../assets/ema/main.scss',
  ]
})
export class NewsComponent implements OnInit {
  public news; 
  public contenido = this.contenidoService.getContenido("news");
  constructor(private newsService: NewsService, private contenidoService: ContenidoService) { }

  ngOnInit() {
    this.newsService.getNews()
    .then(news => {
      this.news = news;
    })
    .catch(err => console.error(err));
  }

}
 