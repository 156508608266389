import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

// interface Customer{
//   agencia_nombre: string
//   apellido : string
//   aviso_privacidad : boolean
//   correo: string
//   created_at : string
//   estado_o_ciudad : string
//   fecha_nacimiento: string
//   invitador: string
//   nombre: string
//   online: boolean
//   telefono: string
//   tipo: string
//   updated_at: string
// }

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  // private itemsCollection: AngularFirestoreCollection<Customer>;
  // items: Observable<Customer[]>;
  public correo:string = '';
  constructor(private afs: AngularFirestore) {
    // this.itemsCollection = afs.collection<Customer>('/customers');    
    // this.items = this.itemsCollection.valueChanges();
    // this.items.subscribe(response =>{
    //   console.log('Items Response ', response);
    // });
  }


  getUser(){
    let user = JSON.parse( localStorage.getItem('currentUser') ); 
    return user;
  }

  getCorreo(){
    return this.correo;
  }

  setUser(user){
    localStorage.setItem('currentUser', JSON.stringify(user));
  }
  
  clearUser(){
    localStorage.clear();
    // localStorage.removeAll();
    // localStorage.removeItem('currentUser');
  }

}
