import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import * as base64 from 'base-64';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminPdfService {
  constructor(
    private firestore: AngularFirestore
  ) {}

  public getPdfByName(name: string) {
    let base64name = base64.encode(name);
    return this.firestore.collection(`${environment.firestorePath}/pdfs`).doc(base64name).snapshotChanges();
  }
}
