import { Component, OnInit } from '@angular/core';
import { MenuComponent } from '../menu/menu.component';
import { ContenidoService } from '../contenido.service';

@Component({
  selector: 'app-cc-loyalty',
  templateUrl: './cc-loyalty.component.html',
  styleUrls: [
  	'../../assets/css/bootstrap.min.css',
	'../../assets/scss/styles.scss', 
	'../../assets/scss/celebrity.scss', 
	'../../assets/scss/fonts.scss',
	'./cc-loyalty.component.css',
  '../../assets/ema/main.scss',

  ]
})
export class CcLoyaltyComponent implements OnInit {
  public contenido = this.contenidoService.getContenido("cc-loyalty");
  constructor(private contenidoService: ContenidoService) {}

  ngOnInit() {
  }

}
