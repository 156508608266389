import { Component, OnInit } from '@angular/core';
import { MenuComponent } from '../menu/menu.component';
import * as $ from 'jquery';
import { TrainingService } from '../training.service';
import { DomSanitizer} from '@angular/platform-browser';
import { ContenidoService } from '../contenido.service';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: [
	'./training.component.css',
	'../../assets/css/bootstrap.min.css',
	'../../assets/scss/styles.scss', 
	'../../assets/scss/celebrity.scss', 
	'../../assets/scss/fonts.scss',
	'../../assets/ema/main.scss', 

	]
})
export class TrainingComponent implements OnInit {
  public trainings;
  //public trainingBanners;
  public contenido = this.contenidoService.getContenido("training");
  constructor(private trainingService: TrainingService, private sanitizer: DomSanitizer, private contenidoService: ContenidoService) { }
  showVideo = false;
  currentVideo = '';

  openVideo = (url, id) => {
    // console.info(id)
    // var video = document.getElementById('iframe_'+id) as HTMLIFrameElement;
    // //video.pause();
    


     this.showVideo = true;
     
     setTimeout(function() {
       $('iframe').attr('src', url)
    }, 500);
  };
  getUrl = (url) => {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getThumb = (url) =>{
    return '//img.youtube.com/vi/' + this.getUrlIframe(url) + '/0.jpg';
  }

  getUrlIframe = (url) => {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length == 11) {
        return match[2];
    } else {
        return 'error';
    }
  }

  closeVideo = () =>{
    this.showVideo = false;
  };
  
  ngOnInit() {
    /*this.trainingService.getTrainingBanner().then(trainingBanners => {
      this.trainingBanners = trainingBanners;
    })
    .catch(err => {
      console.error(err);
    });*/
    this.trainingService.getAllTrainings().then(trainings => {
      this.trainings = trainings;
      setTimeout(function(){
        $("video").each(function() { 
            var src= $(this).attr('src');
            $(this).attr('src',src);
            this.paused ? this.pause() : this.pause();
            $(this).prop('muted', true);
        });
      },25);
    })
    .catch(err => {
      console.error(err);
    });
  }


}
