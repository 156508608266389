import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { SessionService } from '../session.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { environment } from 'src/environments/environment';
import * as glob from '../contenidoGlobal';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: [
    '../../assets/css/bootstrap.min.css',
    '../../assets/scss/styles.scss',
    '../../assets/scss/home.scss',
    '../../assets/scss/icomoon.scss',
    '../../assets/scss/fonts.scss',    
    '../../assets/ema/main.scss',
    './menu.component.css'

  ]
})
export class MenuComponent implements OnInit {
	private customers = firebase.firestore().collection(`${environment.firestorePath}/customers`);
  
	constructor(public router: Router, 
				private session: SessionService,
				public appComponent: AppComponent
				) { }
  title = 'app';
  contador = 1;
  menuApp = glob.contenido;
  /*
  menuApp = [
  		{
  			texto: "Home",
  			link: "home"
  		},
  		{
  			texto: "General",
  			colapsar: false,
  			hijos: [
  				{
  					texto: "Documentos de Viaje",
  					link: "travel-documents"
  				},
				{
					texto: "Preparativos de Viaje",
					link: "travel-prepatation"
				},
				{
					texto: "Servicios a los Huépedes",
					link: "services-guests"
				},
				{
					texto: "Políticas Abordo",
					link: "policies-board"
				},
				// {
				// 	texto: "Espresso"
				// },
				{
					texto: "Políticas de Cancelación y Penalidades de reserva",
					link: "policies-cancelation"
				},
				{
					texto: "Embarque y Desembarque",
					link: "board"
				}
  			]
  		},
  		{
  			texto: "Noticias",
  			link: "news"
  		},
  		{
  			texto: "Training",
  			link: "/training"
  		},
  		{
  			texto: "Royal Caribbean",
  			colapsar: false,
  			hijos: [
  				{
  					texto: "Información General de la Marca",
  					link: "royal-caribbean"
  				},
				{
					texto: "Destinos",
  					link: "ry-destination"
				},
				{
					texto: "Flota",
  					link: "ry-fleet"
				},
				{
					texto: "Complementos para el Crucero",
					link:"ry-accesories"
				},
				{
					texto: "Club de Lealtad",
					link: "ry-loyalty"
				},
				{
					texto: "Next Cruise",
					link: "ry-next-cruise"
				},
				{
					texto: "Materiales",
					link: "ry-media"
				},
				{
					texto: "Videos",
					link: "ry-video"
				},
  			]
  		},
  		{
  			texto: "Celebrity Cruises",
  			colapsar: false,
  			hijos: [
  				{
  					texto: "Información General de la Marca",
  					link: "celebrity-cruises"
  				},
				{
					texto: "Destinos",
					link: "cc-destination"
				},
				{
					texto: "Flota",
					link: "cc-fleet"
				},
				{
					texto: "Complementos para el Crucero",
					link: "cc-accesories"
				},
				{
					texto: "Club de Lealtad",
					link: "cc-loyalty"
				},
				{
					texto: "Future Cruise",
					link: "cc-future-cruise"
				},
				{
					texto: "Materiales",
					link: "cc-media"
				},
				{
					texto: "Videos",
					link: "cc-video"
				},
  			]
  		},
  		{
  			texto: "Azamara Club Cruises",
  			colapsar: false,
  			hijos: [
  				{
  					texto: "Información General de la Marca",
  					link: "azamara"
  				},
				{
					texto: "Destinos",
					link: "az-destination"
				},
				{
					texto: "Flota",
					link: "az-fleet"
				},
				{
					texto: "Complementos para el Crucero",
					link: "az-accesories"
				},
				{
					texto: "Club de Lealtad",
					link: "az-loyalty"
				},
				{
					texto: "Cruise Again",
					link: "az-next-cruise"
				},
				{
					texto: "Materiales",
					link: "az-media"
				},
				{
					texto: "Videos",
					link: "az-video"
				},
  			]
  		},
  		{
  			texto: "Grupos",
  			link: "/grupos"
  		},
  		{
  			texto: "Mayoristas",
  			colapsar: false,
  			hijos: [  				
  				{
  					texto: "Comunicados",
  					child: true,
  					hijos: [
  						{
  							texto: "Finanzas",
  							link: '/mayoristas-comunicados-finanzas'
  						},
						{
							texto: "Operaciones",
							link: '/mayoristas-comunicados-operaciones'
						},
						{
							texto: "Grupos",
							link: '/mayoristas-comunicados-grupos'
						},
						{
							texto: "Marketing",
							link: '/mayoristas-comunicados-marketing'
						},
						{
							texto: "Ventas",
							link: '/mayoristas-comunicados-ventas'
						},
  					]
  				}
  			]
  		}
  	];*/

	menu = () => {
		$('.menu').on('click', (event) => {
      		$('.menu-top').toggleClass('menu-top-click');
      		$('.menu-middle').toggleClass('menu-middle-click');
      		$('.menu-bottom').toggleClass('menu-bottom-click'); 
      		event.preventDefault();
    	});
	}

	logout = ($event) => {
		
		console.log(JSON.stringify(this.session.getUser()));
		let correo =this.session.correo;
		console.log(this.session.correo);
	
		let queryRef = this.customers.where('correo', '==', correo).get()
				.then(snapshot => {
					snapshot.forEach(doc => {
						// console.log(doc.id, '=>', doc.data());
						let customerRef = this.customers.doc(doc.id);
						let updateSingle = customerRef.update({ online: false });
						this.session.clearUser();
						localStorage.removeItem("jwt");
						localStorage.removeItem("perfil");
						this.router.navigate(["/login"]);
					}
				);
			})
			.catch(err => {				
			});
			this.session.clearUser();
			localStorage.removeItem("jwt");
			localStorage.removeItem("perfil");
			localStorage.removeItem('tipo');
			this.router.navigate(["/login"]);
	};

	activarMenu = () => {
		$('.menu').click(() => {
			if (screen.width<768) {
				if (this.contador == 1){
					$('.menu-colaps').animate({
						left: '0%'
					});
					$( ".overlay" ).addClass( "show-ol" );
					$( "body" ).addClass( "body-fixed" );
					this.contador = 0;
				} else {
					this.contador = 1;
					$('.menu-colaps').animate({
						left: '104%'
					});
					$( ".overlay" ).removeClass( "show-ol" );
					$( "body" ).removeClass( "body-fixed" );
				};
	        } else if (screen.width<1025) {
				if (this.contador == 1){
					$('.menu-colaps').animate({
						left: '50%'
					});
					$( ".overlay" ).addClass( "show-ol" );
					$( "body" ).addClass( "body-fixed" );
					this.contador = 0;
				} else {
					this.contador = 1;
					$('.menu-colaps').animate({
						left: '104%'
					});
					$( ".overlay" ).removeClass( "show-ol" );
					$( "body" ).removeClass( "body-fixed" );
				};
			} else {
				if (this.contador == 1){
					$('.menu-colaps').animate({
						left: '70%'
					});
					$( ".overlay" ).addClass( "show-ol" );
					$( "body" ).addClass( "body-fixed" );
					this.contador = 0;
				} else {
					this.contador = 1;
					$('.menu-colaps').animate({
						left: '104%'
					});
					$( ".overlay" ).removeClass( "show-ol" );
					$( "body" ).removeClass( "body-fixed" );
				};
			};
			
		});
	}

	activarMenu2 = () => {
		if (screen.width<768) {
				if (this.contador == 1){
					$('.menu-colaps').animate({
						left: '0%'
					});
					$( ".overlay" ).addClass( "show-ol" );
					$( "body" ).addClass( "body-fixed" );
					this.contador = 0;
				} else {
					this.contador = 1;
					$('.menu-colaps').animate({
						left: '104%'
					});
					$( ".overlay" ).removeClass( "show-ol" );
					$( "body" ).removeClass( "body-fixed" );
				};
	    } else if (screen.width<1025) {
				if (this.contador == 1){
					$('.menu-colaps').animate({
						left: '50%'
					});
					$( ".overlay" ).addClass( "show-ol" );
					$( "body" ).addClass( "body-fixed" );
					this.contador = 0;
				} else {
					this.contador = 1;
					$('.menu-colaps').animate({
						left: '104%'
					});
					$( ".overlay" ).removeClass( "show-ol" );
					$( "body" ).removeClass( "body-fixed" );
				};
		} else {
				if (this.contador == 1){
					$('.menu-colaps').animate({
						left: '70%'
					});
					$( ".overlay" ).addClass( "show-ol" );
					$( "body" ).addClass( "body-fixed" );
					this.contador = 0;
				} else {
					this.contador = 1;
					$('.menu-colaps').animate({
						left: '104%'
					});
					$( ".overlay" ).removeClass( "show-ol" );
					$( "body" ).removeClass( "body-fixed" );
				};
		};
			$('.menu-top').toggleClass('menu-top-click');
      		$('.menu-middle').toggleClass('menu-middle-click');
      		$('.menu-bottom').toggleClass('menu-bottom-click'); 
      		event.preventDefault();
	}

	colapsar = (index, item, e, j) => {
		console.log(item);
		window.scrollTo(0, 0)
		e.preventDefault();
		if(item.hasOwnProperty('link')){
			this.appComponent.checkBuscador();
			this.appComponent.resetValues();
			if(item.link == "home")
				window.location.href = "/#/";
			else
				this.router.navigate([item.link]);
			this.esconderMenu();
			this.activarMenu2()
		}else{
			if(item.hasOwnProperty('child')){
				$($($($($(".father")[index]).children()[1]).children()[j]).children()[1]).slideToggle();
				if (!item.hasOwnProperty('hijos'))
					this.activarMenu2();
			}else{
				for (var i = 0; i < this.menuApp.length; i++) {
					if(i == index){
						$($($(".father")[i]).children()[1]).slideToggle();
					}else{
						$($($(".father")[i]).children()[1]).slideUp();
					}
				}
			}
		}

	}

	esconderMenu = () => {
		$('.children').slideUp();
		//this.activarMenu();
		//this.menu();
	}
  ngOnInit() {
  	this.activarMenu();
	this.menu();
	if(!localStorage.tipo){
		this.menuApp.splice(8, 1);
	}	
  }

}
