import { Component, OnInit } from '@angular/core';
import { MenuComponent } from '../menu/menu.component';
import { ContenidoService } from '../contenido.service';

@Component({
  selector: 'app-services-guests',
  templateUrl: './services-guests.component.html',
  styleUrls: [
    '../../assets/css/bootstrap.min.css',
    '../../assets/scss/styles.scss', 
    '../../assets/scss/general-sg.scss', 
    '../../assets/scss/fonts.scss',
    './services-guests.component.css',
    '../../assets/ema/main.scss',

    ]
})
export class ServicesGuestsComponent implements OnInit {
  public contenido = this.contenidoService.getContenido("services-guests");
  constructor(private contenidoService: ContenidoService) { }

  ngOnInit() {
  }

}
