import { Component, OnInit, ElementRef } from '@angular/core';
import * as $ from 'jquery';
import { VideosService } from '../videos.service';
@Component({
  selector: 'app-az-video',
  templateUrl: './az-video.component.html',
  styleUrls: [
  './az-video.component.css',
	'../../assets/cssDali/general.scss',		
	'../../assets/scss/styles.scss', 
	'../../assets/scss/celebrity.scss', 
	'../../assets/scss/fonts.scss',
	'../../assets/ema/main.scss', 
  ]

})
export class AzVideoComponent implements OnInit {
  public videos;
  constructor(private videoService: VideosService) { }
  showVideo = false;
  currentVideo = '';

  openVideo = (url) => {
     this.showVideo = true;
     let urlToSee = '//www.youtube.com/embed/'+this.getUrlIframe(url);
     setTimeout(function() {
       $('iframe').attr('src', urlToSee)
    }, 500);
  };  

  getThumb = (url) =>{
    return '//img.youtube.com/vi/' + this.getUrlIframe(url) + '/0.jpg';
  }

  getUrlIframe = (url) => {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length == 11) {
        return match[2];
    } else {
        return 'error';
    }
  }

  closeVideo = () =>{
    this.showVideo = false;
  };
  
  ngOnInit() {
    this.videoService.getAllVideos('Azamara').then(videos => {
      this.videos = videos;
    })
    .catch(err => {
      console.error(err);
    });
  }

}
