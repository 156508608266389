import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/**IMPORT MODULOS CREADOS**/
import { PrincipalComponent } from './principal/principal.component';
import { GrupoComponent } from './grupo/grupo.component';
import { NewsComponent } from './news/news.component';
import { RoyalCaribbeanComponent } from './royal-caribbean/royal-caribbean.component';
import { CelebrityCruisesComponent } from './celebrity-cruises/celebrity-cruises.component';
import { AzamaraComponent } from './azamara/azamara.component';
import { ServicesGuestsComponent } from './services-guests/services-guests.component';
import { TravelDocumentsComponent } from './travel-documents/travel-documents.component';
import { TravelPrepatationComponent } from './travel-prepatation/travel-prepatation.component';
import { BoardComponent } from './board/board.component';
import { PoliciesBoardComponent } from './policies-board/policies-board.component';
import { PoliciesCancelationComponent } from './policies-cancelation/policies-cancelation.component';
import { RyDestinationComponent } from './ry-destination/ry-destination.component';
import { RyFleetComponent } from './ry-fleet/ry-fleet.component';
import { RyAccesoriesComponent } from './ry-accesories/ry-accesories.component';
import { RyVideoComponent } from './ry-video/ry-video.component';
import { RyMediaComponent } from './ry-media/ry-media.component';
import { RyLoyaltyComponent } from './ry-loyalty/ry-loyalty.component';
import { RyNextCruiseComponent } from './ry-next-cruise/ry-next-cruise.component';
import { CcDestinationComponent } from './cc-destination/cc-destination.component';
import { CcFleetComponent } from './cc-fleet/cc-fleet.component';
import { CcAccesoriesComponent } from './cc-accesories/cc-accesories.component';
import { CcVideoComponent } from './cc-video/cc-video.component';
import { CcMediaComponent } from './cc-media/cc-media.component';
import { CcLoyaltyComponent } from './cc-loyalty/cc-loyalty.component';
import { CcFutureCruiseComponent } from './cc-future-cruise/cc-future-cruise.component';
import { AzDestinationComponent } from './az-destination/az-destination.component';
import { AzFleetComponent } from './az-fleet/az-fleet.component';
import { AzAccesoriesComponent } from './az-accesories/az-accesories.component';
import { AzVideoComponent } from './az-video/az-video.component';
import { AzMediaComponent } from './az-media/az-media.component';
import { AzLoyaltyComponent } from './az-loyalty/az-loyalty.component';
import { AzNextCruiseComponent } from './az-next-cruise/az-next-cruise.component';
import { LoginComponent } from './login/login.component';
import { TrainingComponent } from './training/training.component';
import { RegistroComponent } from './registro/registro.component';
import { RegistroMayotistaComponent } from './registro-mayotista/registro-mayotista.component';
import { RegistroMinoristaComponent } from './registro-minorista/registro-minorista.component';
import { MayoristasComunicadosFinanzasComponent } from './mayoristas-comunicados-finanzas/mayoristas-comunicados-finanzas.component';
import { MayoristasComunicadosGruposComponent } from './mayoristas-comunicados-grupos/mayoristas-comunicados-grupos.component';
import { MayoristasComunicadosMArketingComponent } from './mayoristas-comunicados-marketing/mayoristas-comunicados-marketing.component';
import { MayoristasComunicadosOperacionesComponent } from './mayoristas-comunicados-operaciones/mayoristas-comunicados-operaciones.component';
import { MayoristasComunicadosVentasComponent } from './mayoristas-comunicados-ventas/mayoristas-comunicados-ventas.component';
import { RoyalCaribbeanGroupComponent } from './royal-caribbean-group/royal-caribbean-group.component';
import { CelebrityCruisesGroupComponent } from './celebrity-cruises-group/celebrity-cruises-group.component';
import { AzamaraGroupComponent } from './azamara-group/azamara-group.component';
import { AuthGuard } from './auth.guard';



const routes: Routes = [
	{
		path: '',
		canActivate: [AuthGuard],
		children: [
			{ path: '',component: PrincipalComponent },
			{ path: 'royal-caribbean',component: RoyalCaribbeanComponent },
			{ path: 'ry-video', component: RyVideoComponent},
			{ path: 'ry-media', component: RyMediaComponent},
			{ path: 'ry-loyalty', component: RyLoyaltyComponent},
			{ path: 'ry-next-cruise', component: RyNextCruiseComponent},
			{ path: 'ry-accesories', component: RyAccesoriesComponent},
			{ path: 'ry-fleet', component: RyFleetComponent },
			{ path: 'ry-destination', component: RyDestinationComponent},
			{ path: 'grupos', component: GrupoComponent},
			{ path: 'celebrity-cruises', component: CelebrityCruisesComponent},
			{ path: 'cc-video',component: CcVideoComponent },
			{ path: 'cc-media',component: CcMediaComponent},
			{ path: 'cc-loyalty',component: CcLoyaltyComponent},
			{ path: 'cc-future-cruise',component: CcFutureCruiseComponent},
			{ path: 'cc-accesories',component: CcAccesoriesComponent},
			{ path: 'cc-fleet',component: CcFleetComponent},
			{ path: 'cc-destination',component: CcDestinationComponent},
			{ path: 'azamara',component: AzamaraComponent},
			{ path: 'az-video', component: AzVideoComponent },
			{ path: 'az-media', component: AzMediaComponent },
			{ path: 'az-loyalty', component: AzLoyaltyComponent},
			{ path: 'az-next-cruise', component: AzNextCruiseComponent},
			{ path: 'az-accesories', component: AzAccesoriesComponent},
			{ path: 'az-fleet', component: AzFleetComponent},
			{ path: 'az-destination',component: AzDestinationComponent},
			{ path: 'services-guests',component: ServicesGuestsComponent},
			{ path: 'travel-documents',component: TravelDocumentsComponent},
			{ path: 'travel-prepatation',component: TravelPrepatationComponent},
			{ path: 'board',component: BoardComponent},
			{ path: 'policies-board',component: PoliciesBoardComponent},
			{ path: 'policies-cancelation',component: PoliciesCancelationComponent},
			{ path: 'news',component: NewsComponent},
			{ path: 'training',component: TrainingComponent},
			{ path: 'mayoristas-comunicados-finanzas',component: MayoristasComunicadosFinanzasComponent},
			{ path: 'mayoristas-comunicados-grupos', component: MayoristasComunicadosGruposComponent},
			{ path: 'mayoristas-comunicados-marketing',component: MayoristasComunicadosMArketingComponent},
			{ path: 'mayoristas-comunicados-operaciones',component: MayoristasComunicadosOperacionesComponent},
			{ path: 'mayoristas-comunicados-ventas', component: MayoristasComunicadosVentasComponent},
			{ path: 'royal-caribbean-group',component: RoyalCaribbeanGroupComponent},
			{ path: 'celebrity-cruises-group',component: CelebrityCruisesGroupComponent},
			{ path: 'azamara-group',component: AzamaraGroupComponent},
		]
	},
	{
		path: 'registro',
		component: RegistroComponent
	},
	{
		path: 'registro-mayorista',
		component: RegistroMayotistaComponent
	},
	{
		path: 'registro-minorista',
		component: RegistroMinoristaComponent
	},
	{ path:'login', component: LoginComponent},
  	{ path: '**', component: LoginComponent },
]

@NgModule({
  exports: [
    RouterModule
  ],
  imports: [ 
  	RouterModule.forRoot(routes, {useHash: true}) 
  ],
})
export class AppRoutingModule {}
