import { Directive, Input, ElementRef, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

interface Link {
  url: string;
}

@Directive({
  selector: '[adminHref]'
})
export class AdminHrefDirective implements OnInit{
  @Input('adminHref') path: string;

  constructor(private elementRef: ElementRef, private firestore: AngularFirestore) { }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.firestore.doc<Link>(this.path).snapshotChanges().subscribe(snapshot => {
      let doc = snapshot.payload;
      if(doc.exists) {
        let data = doc.data();
        this.elementRef.nativeElement.href = data['url']
      }
    })
  }

}
