import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PromocionesService {

  constructor(private firestore: AngularFirestore) { }

  /**
   * Crea un observable para lo obtencion de todas las promociones
   */
  public getAllPromociones() {
    return this.firestore.collection(`${environment.firestorePath}/promociones`).snapshotChanges()
  }
}
