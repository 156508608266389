import { Component, OnInit } from '@angular/core';
import { MenuComponent } from '../menu/menu.component';
import { ContenidoService } from '../contenido.service';

@Component({
  selector: 'app-celebrity-cruises',
  templateUrl: './celebrity-cruises.component.html',
  styleUrls: [
    './celebrity-cruises.component.css',
    '../../assets/css/bootstrap.min.css',
    '../../assets/scss/styles.scss', 
    '../../assets/scss/celebrity.scss', 
    '../../assets/scss/fonts.scss',
    '../../assets/ema/main.scss', 

  ]
})
export class CelebrityCruisesComponent implements OnInit {
  public contenido = this.contenidoService.getContenido("celebrity-cruises");
  constructor(private contenidoService: ContenidoService) {}

  ngOnInit() {
  }

}
