import { Component, OnInit } from '@angular/core';
import { MenuComponent } from '../menu/menu.component';
import * as $ from 'jquery';
import { VideosService } from '../videos.service';
@Component({
  selector: 'app-ry-video',
  templateUrl: './ry-video.component.html',
  styleUrls: [
    '../../assets/css/bootstrap.min.css',
    '../../assets/scss/styles.scss', 
    '../../assets/scss/royal.scss', 
    '../../assets/scss/fonts.scss',
    './ry-video.component.css',
    '../../assets/ema/main.scss',

  ]
})
export class RyVideoComponent implements OnInit {
  public videos;
  constructor(private videoService: VideosService) { }
  showVideo = false;
  currentVideo = '';

  openVideo = (url) => {
     this.showVideo = true;
     let urlToSee = '//www.youtube.com/embed/'+this.getUrlIframe(url);
     setTimeout(function() {
       $('iframe').attr('src', urlToSee)
    }, 500);
  };  

  getThumb = (url) =>{
    return '//img.youtube.com/vi/' + this.getUrlIframe(url) + '/0.jpg';
  }

  getUrlIframe = (url) => {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length == 11) {
        return match[2];
    } else {
        return 'error';
    }
  }

  closeVideo = () =>{
    this.showVideo = false;
  };
  
  ngOnInit() {
    this.videoService.getAllVideos('Royal').then(videos => {
      this.videos = videos;
    })
    .catch(err => {
      console.error(err);
    });
  }

}
