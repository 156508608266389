import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RegistroService } from './services/registro.service';
import * as $ from 'jquery';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import * as moment from "moment";

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css',    
    '../../assets/scss/styles.scss', 
    '../../assets/scss/registro.scss', 
    '../../assets/scss/fonts.scss',
    '../../assets/ema/main.scss', 
    ]
})
export class RegistroComponent implements OnInit {
  private customers = firebase.firestore().collection(`${environment.firestorePath}/customers`);
  // registerForm: FormGroup;
  isUsernameExisting: boolean = false;
  isValidFecha: boolean = false;
  constructor(private fb: FormBuilder, public router: Router, public addService: RegistroService) {}
  agente={
    correo: "",
    agencia_nombre:"",
    nombre:"",
    apellido:"",
    telefono:"",
    fecha_nacimiento:{
      year    : '',
      month   : '',
      day     : ''
    },
    tipo: "minorista",
    estado_o_ciudad:"",
    aviso_privacidad:false,
    invitador: '',
    online: false,
    password:"password"
  }
  loading = false;
  loadingtext = false;
  registro = () => {    
    console.log(this.agente.fecha_nacimiento)
    let dateBorn: any = this.agente.fecha_nacimiento.year+'-'+this.agente.fecha_nacimiento.month+'-'+this.agente.fecha_nacimiento.day;
    dateBorn = new Date(dateBorn);
    this.agente.fecha_nacimiento = dateBorn.toISOString().slice(0,10);
    this.loading = true;    
    // console.log('Soy un agente: ', this.agente);
    
    //TODO created_at, updated_at, fecha formato?
    let queryRef = this.customers.add(this.agente)
      .then(ref => {
        this.loadingtext = true;
        setTimeout( () =>{
          this.loading = false;
          this.router.navigate(["/"]);
        },4000);
      })
      .catch(err => {
        console.error(err);
        this.loading = false;
      });  
  }
  ngOnInit() {
    this.createRegisterForm();
  }

  createRegisterForm(){
    
  }
  validateFecha(){
    var d = this.agente.fecha_nacimiento;
    var fechaNacimiento = moment(d.year+"-"+d.month+"-"+d.day).format('YYYY-MM-DD');
    var maxDate=moment().subtract(18, 'years').format('YYYY-MM-DD').toString();
    var minDate=moment().subtract(118, 'years').format('YYYY-MM-DD').toString();
    var diffMaxDate = (moment.duration(moment(maxDate).diff(fechaNacimiento))).asHours();
    var diffMinDate = (moment.duration(moment(minDate).diff(fechaNacimiento))).asHours();
    this.isValidFecha = false;
    if(diffMaxDate >= 0 && diffMinDate <= 0)
      this.isValidFecha = true;
  }

  onSubmit(){
    // console.log(this.registerForm.value);
  }


  validateCorreo(){
    let regex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    if (!regex.test(this.agente.correo)) {
        return;
    }

    let queryRef = this.customers.where('correo', '==', this.agente.correo).get()
    .then(snapshot => {
      if (snapshot.size > 0) {
        this.isUsernameExisting = true;
      } else {
          this.isUsernameExisting = false;
      }
    })
    .catch(err => {
      console.log('Error getting documents', err);
    });
  }

}
