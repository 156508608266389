import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PrincipalComponent } from './principal/principal.component';
import { GrupoComponent } from './grupo/grupo.component';
import { RoyalCaribbeanComponent } from './royal-caribbean/royal-caribbean.component';
import { CelebrityCruisesComponent } from './celebrity-cruises/celebrity-cruises.component';
import { AzamaraComponent } from './azamara/azamara.component';
import { ServicesGuestsComponent } from './services-guests/services-guests.component';
import { TravelDocumentsComponent } from './travel-documents/travel-documents.component';
import { TravelPrepatationComponent } from './travel-prepatation/travel-prepatation.component';
import { BoardComponent } from './board/board.component';
import { PoliciesBoardComponent } from './policies-board/policies-board.component';
import { PoliciesCancelationComponent } from './policies-cancelation/policies-cancelation.component';
import { RyDestinationComponent } from './ry-destination/ry-destination.component';
import { RyFleetComponent } from './ry-fleet/ry-fleet.component';
import { RyAccesoriesComponent } from './ry-accesories/ry-accesories.component';
import { RyVideoComponent } from './ry-video/ry-video.component';
import { RyLoyaltyComponent } from './ry-loyalty/ry-loyalty.component';
import { RyNextCruiseComponent } from './ry-next-cruise/ry-next-cruise.component';
import { CcVideoComponent } from './cc-video/cc-video.component';
import { CcAccesoriesComponent } from './cc-accesories/cc-accesories.component';
import { CcDestinationComponent } from './cc-destination/cc-destination.component';
import { CcFleetComponent } from './cc-fleet/cc-fleet.component';
import { CcLoyaltyComponent } from './cc-loyalty/cc-loyalty.component';
import { CcFutureCruiseComponent } from './cc-future-cruise/cc-future-cruise.component';
import { AzAccesoriesComponent } from './az-accesories/az-accesories.component';
import { AzDestinationComponent } from './az-destination/az-destination.component';
import { AzFleetComponent } from './az-fleet/az-fleet.component';
import { AzLoyaltyComponent } from './az-loyalty/az-loyalty.component';
import { AzNextCruiseComponent } from './az-next-cruise/az-next-cruise.component';
import { AzVideoComponent } from './az-video/az-video.component';
import { NewsComponent } from './news/news.component';
import { TrainingComponent } from './training/training.component';
import { LoginComponent } from './login/login.component';
import { RegistroComponent } from './registro/registro.component';
import { RegistroMinoristaComponent } from './registro-minorista/registro-minorista.component';
import { RegistroMayotistaComponent } from './registro-mayotista/registro-mayotista.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MenuComponent } from './menu/menu.component';
import { MayoristasComunicadosComponent } from './mayoristas-comunicados/mayoristas-comunicados.component';
import { MayoristasComunicadosFinanzasComponent } from './mayoristas-comunicados-finanzas/mayoristas-comunicados-finanzas.component';
import { MayoristasComunicadosOperacionesComponent } from './mayoristas-comunicados-operaciones/mayoristas-comunicados-operaciones.component';
import { MayoristasComunicadosGruposComponent } from './mayoristas-comunicados-grupos/mayoristas-comunicados-grupos.component';
import { MayoristasComunicadosMArketingComponent } from './mayoristas-comunicados-marketing/mayoristas-comunicados-marketing.component';
import { MayoristasComunicadosVentasComponent } from './mayoristas-comunicados-ventas/mayoristas-comunicados-ventas.component';
import { AzMediaComponent } from './az-media/az-media.component';
import { CcMediaComponent } from './cc-media/cc-media.component';
import { RyMediaComponent } from './ry-media/ry-media.component';
import { FooterComponent } from './footer/footer.component';
import { RoyalCaribbeanGroupComponent } from './royal-caribbean-group/royal-caribbean-group.component';
import { CelebrityCruisesGroupComponent } from './celebrity-cruises-group/celebrity-cruises-group.component';
import { AzamaraGroupComponent } from './azamara-group/azamara-group.component';
import { SessionService } from './session.service';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { NewsService } from './news.service';
import { MaterialesService } from './materiales.service';
import { BannerService } from './banner.service';
import { ComunicadosService } from './comunicados.service';
import { VideosService } from './videos.service';
import { AdminastrableHrefDirective } from './adminastrable-href.directive';
import { AdminHrefDirective } from './admin-href.directive';
import { ContenidoService } from './contenido.service';


firebase.initializeApp(environment.firebase);
firebase.firestore().settings(environment.firestoreSetting);

@NgModule({
	declarations: [
		AppComponent,
		PrincipalComponent,
		GrupoComponent,
		RoyalCaribbeanComponent,
		CelebrityCruisesComponent,
		AzamaraComponent,
		ServicesGuestsComponent,
		TravelDocumentsComponent,
		TravelPrepatationComponent,
		BoardComponent,
		PoliciesBoardComponent,
		PoliciesCancelationComponent,
		RyDestinationComponent,
		RyFleetComponent,
		RyAccesoriesComponent,
		RyVideoComponent,
		RyLoyaltyComponent,
		RyNextCruiseComponent,
		CcVideoComponent,
		CcAccesoriesComponent,
		CcDestinationComponent,
		CcFleetComponent,
		CcLoyaltyComponent,
		CcFutureCruiseComponent,
		AzAccesoriesComponent,
		AzDestinationComponent,
		AzFleetComponent,
		AzLoyaltyComponent,
		AzNextCruiseComponent,
		AzVideoComponent,
		NewsComponent,
		TrainingComponent,
		LoginComponent,
		RegistroComponent,
		RegistroMinoristaComponent,
		RegistroMayotistaComponent,
		MenuComponent,
		MayoristasComunicadosComponent,
		MayoristasComunicadosFinanzasComponent,
		MayoristasComunicadosOperacionesComponent,
		MayoristasComunicadosGruposComponent,
		MayoristasComunicadosMArketingComponent,
		MayoristasComunicadosVentasComponent,
		AzMediaComponent,
		CcMediaComponent,
		RyMediaComponent,
		FooterComponent,
		RoyalCaribbeanGroupComponent,
		CelebrityCruisesGroupComponent,
		AzamaraGroupComponent,
		AdminastrableHrefDirective,
		AdminHrefDirective,
  	],
  	imports: [
		BrowserModule,
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		NgbModule,
		AngularFireModule.initializeApp(environment.firebase),
		AngularFirestoreModule, // imports firebase/firestore, only needed for database features
		AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
		AngularFireStorageModule // imports firebase/storage only needed for storage features
  	],
  	providers: [
		SessionService,
		NewsService,
		MaterialesService,
		BannerService,
		ComunicadosService,
		VideosService
	  ],
 	bootstrap: [AppComponent]
})

export class AppModule { }
