import { Component, OnInit } from '@angular/core';
import { MenuComponent } from '../menu/menu.component';
import { ContenidoService } from '../contenido.service';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.css',
    '../../assets/css/bootstrap.min.css',
    '../../assets/scss/styles.scss', 
    '../../assets/scss/general-board.scss', 
    '../../assets/scss/fonts.scss',
    '../../assets/cssDali/general.scss']
})
export class BoardComponent implements OnInit {
  public contenido = this.contenidoService.getContenido("board");
  constructor(private contenidoService: ContenidoService) {}

  ngOnInit() {
  }

}
