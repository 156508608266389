import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionService } from 'src/app/session.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

	urlBase = 'http://api-agentesrc.amarellodev.com';
	headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'dataType': 'JSON'
	});

  	constructor(private http: HttpClient, private session:SessionService) { }

  	login = (objeto) => {
  		return this.http.post(this.urlBase+"/login", objeto);
	  }
	  
	isAuthenticated(){
		if (this.session.getUser()!=null){
			return true;
		}
		return false;
	}
}
