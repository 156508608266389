import { Component, OnInit } from '@angular/core';
import { MenuComponent } from '../menu/menu.component';
import { ContenidoService } from '../contenido.service';

@Component({
  selector: 'app-cc-future-cruise',
  templateUrl: './cc-future-cruise.component.html',
  styleUrls: [
  '../../assets/css/bootstrap.min.css',
    '../../assets/scss/styles.scss', 
    '../../assets/scss/celebrity.scss', 
    '../../assets/scss/fonts.scss',
    './cc-future-cruise.component.css',
    '../../assets/ema/main.scss',

  ]
})
export class CcFutureCruiseComponent implements OnInit {
  public contenido = this.contenidoService.getContenido("cc-future-cruise");
  constructor(private contenidoService: ContenidoService) {}

  ngOnInit() {
  }

}
