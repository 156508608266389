import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ComunicadosService {
  private comunicadosRef: firebase.firestore.CollectionReference;

  constructor( ) { 
    this.comunicadosRef = firebase.firestore().collection(`${environment.firestorePath}/comunicados`);
  }
  

  async getAllComunicadosbyCategory(category: string){
    try {
      const querySnapshot = await this.comunicadosRef
                            .where('category', '==',category)
                            .orderBy('datePost','desc')
                            .get();

      if (querySnapshot.empty) {
        throw new Error(`El endpoint no tiene registros...`);
      }
      
      const comunicados = querySnapshot.docs.map(doc => {
        const obj = doc.data();
        obj.id = doc.id;
        return obj;
      });

      return comunicados; 
    } catch (error) {
      throw new Error(error);
    }
  }
}
