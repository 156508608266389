import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RegistroService {

	urlBase = 'http://api-agentesrc.amarellodev.com';
	headers = new HttpHeaders({
		'Content-Type': 'application/json',
		'dataType': 'JSON'
	});

  	constructor(private http: HttpClient) { }

  	registro = (objeto) => {
  		return this.http.post(this.urlBase+"/agentes", objeto);
  	}

  
}
