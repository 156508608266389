import { Component, OnInit } from '@angular/core';
import { MenuComponent } from '../menu/menu.component';
import { ContenidoService } from '../contenido.service';

@Component({
  selector: 'app-ry-next-cruise',
  templateUrl: './ry-next-cruise.component.html',
  styleUrls: [
  '../../assets/css/bootstrap.min.css',
  '../../assets/scss/styles.scss', 
  '../../assets/scss/royal.scss', 
  '../../assets/scss/fonts.scss',
  './ry-next-cruise.component.css',
  '../../assets/ema/main.scss',
  ]
})
export class RyNextCruiseComponent implements OnInit {
  public contenido = this.contenidoService.getContenido("ry-next-cruise");
  constructor(private contenidoService: ContenidoService) {}

  ngOnInit() {
  }

}
