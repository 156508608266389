import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class MaterialesService {
  private materialesRef: firebase.firestore.CollectionReference;

  constructor() { 
    this.materialesRef = firebase.firestore().collection(`${environment.firestorePath}/materiales`);
  }


  async getMaterialesByMarc(marca:string){
    try {
      const querySnapshot = await this.materialesRef.where('marca', '==',marca).get();

      if (querySnapshot.empty) {
        throw new Error(`El endpoint no tiene registros...`);
      }
      
      const news = querySnapshot.docs.map(doc => {
        const obj = doc.data();
        obj.id = doc.id;
        return obj;
      });

      return _.orderBy(news,['datePost','titulo'],['desc','asc']); 
    } catch (error) {
      throw new Error(error);
    }
  }
}
