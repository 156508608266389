import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BannerService {
  private bannersRef: firebase.firestore.CollectionReference;

  constructor() { 
    this.bannersRef = firebase.firestore().collection(`${environment.firestorePath}/banners`);
  }


  async getAllBanners(){
    try {
      const querySnapshot = await this.bannersRef.get();

      if (querySnapshot.empty) {
        throw new Error(`El endpoint no tiene registros...`);
      }
      
      const news = querySnapshot.docs.map(doc => {
      
        const obj = doc.data();
        obj.id = doc.id;
        return obj;
      });

      return news; 
    } catch (error) {
      throw new Error(error);
    }
  }
}
