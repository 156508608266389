import { Component, OnInit } from '@angular/core';
import { ContenidoService } from '../contenido.service';

@Component({
  selector: 'app-az-fleet',
  templateUrl: './az-fleet.component.html',
  styleUrls: [
	'../../assets/css/bootstrap.min.css',
	'../../assets/scss/styles.scss', 
	'../../assets/scss/azamara.scss', 
	'../../assets/scss/fonts.scss',
	'./az-fleet.component.css',
	'../../assets/cssDali/general.scss'
	]
})
export class AzFleetComponent implements OnInit {
  public contenido = this.contenidoService.getContenido("az-fleet");
  constructor(private contenidoService: ContenidoService) {}

  ngOnInit() {
  }

}
