import { Component, OnInit } from '@angular/core';
import { MenuComponent } from '../menu/menu.component';
import { ContenidoService } from '../contenido.service';

@Component({
  selector: 'app-travel-documents',
  templateUrl: './travel-documents.component.html',
  styleUrls: [
    '../../assets/css/bootstrap.min.css',
    '../../assets/scss/styles.scss', 
    '../../assets/scss/general-td.scss', 
    '../../assets/scss/fonts.scss',
    './travel-documents.component.css',
    '../../assets/ema/main.scss',
    ]
})
export class TravelDocumentsComponent implements OnInit {
  public contenido = this.contenidoService.getContenido("travel-documents");
  constructor(private contenidoService: ContenidoService) {}
  ngOnInit() {
  }

}
