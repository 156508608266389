import { Component, OnInit } from '@angular/core';
import { MenuComponent } from '../menu/menu.component';
import { ContenidoService } from '../contenido.service';

@Component({
  selector: 'app-policies-cancelation',
  templateUrl: './policies-cancelation.component.html',
  styleUrls: ['./policies-cancelation.component.css',
    '../../assets/css/bootstrap.min.css',
    '../../assets/scss/styles.scss', 
    '../../assets/scss/general-pc.scss', 
    '../../assets/css/fonts.css',
    '../../assets/ema/main.scss', 
    ]
})
export class PoliciesCancelationComponent implements OnInit {
  public contenido = this.contenidoService.getContenido("policies-cancelation");
  constructor(private contenidoService: ContenidoService) {}

  ngOnInit() {
  }

}
