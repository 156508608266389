import { Component, OnInit } from '@angular/core';
import { ContenidoService } from '../contenido.service';

@Component({
  selector: 'app-azamara',
  templateUrl: './azamara.component.html',
  styleUrls: [
    './azamara.component.css',
    '../../assets/css/bootstrap.min.css',
    '../../assets/css/styles.css', 
    '../../assets/scss/azamara.scss', 
    '../../assets/css/fonts.css',
    '../../assets/cssDali/general.scss'
  ]
})
export class AzamaraComponent implements OnInit {
  public contenido = this.contenidoService.getContenido("azamara");
  constructor(private contenidoService: ContenidoService) {}

  ngOnInit() { }

}
