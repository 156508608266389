import { Component, OnInit } from '@angular/core';
import { ContenidoService } from '../contenido.service';

@Component({
  selector: 'app-cc-fleet',
  templateUrl: './cc-fleet.component.html',
  styleUrls: [
    '../../assets/css/bootstrap.min.css',
    '../../assets/scss/styles.scss', 
    '../../assets/scss/celebrity.scss',
    '../../assets/scss/fonts.scss',
    '../../assets/ema/main.scss',

  './cc-fleet.component.css'
  ]
})
export class CcFleetComponent implements OnInit {
  public contenido = this.contenidoService.getContenido("cc-fleet");
  constructor(private contenidoService: ContenidoService) {}

  ngOnInit() {
  }

}
