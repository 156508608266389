import { Component, OnInit } from '@angular/core';
import { ComunicadosService } from '../comunicados.service';

@Component({
  selector: 'app-mayoristas-comunicados-ventas',
  templateUrl: './mayoristas-comunicados-ventas.component.html',
  styleUrls: [
  	'./mayoristas-comunicados-ventas.component.css',
  	'../../assets/scss/styles.scss',
	'../../assets/scss/general-board.scss', 
    '../../assets/scss/fonts.scss',    
    '../../assets/ema/main.scss',
  ]
})
export class MayoristasComunicadosVentasComponent implements OnInit {
  public comunicados;

  constructor(private comunicadosService: ComunicadosService) { }

  ngOnInit() {
    this.comunicadosService.getAllComunicadosbyCategory('Ventas')
      .then(response => {
        this.comunicados = response;
      })
      .catch(err => console.error(err));
  }

}
