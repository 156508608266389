import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-celebrity-cruises-group',
  templateUrl: './celebrity-cruises-group.component.html',
  styleUrls: ['./celebrity-cruises-group.component.scss']
})
export class CelebrityCruisesGroupComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(){
  	window.scrollTo(0, 0);
  }

}
