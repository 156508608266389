import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-registro-minorista',
  templateUrl: './registro-minorista.component.html',
  styleUrls: ['./registro-minorista.component.css',
    '../../assets/css/bootstrap.min.css',
    '../../assets/scss/styles.scss', 
    '../../assets/scss/registro.scss', 
    '../../assets/scss/fonts.scss']
})
export class RegistroMinoristaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
