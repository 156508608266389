import { Component, OnInit } from '@angular/core';
import { ContenidoService } from '../contenido.service';

@Component({
  selector: 'app-az-destination',
  templateUrl: './az-destination.component.html',
  styleUrls: [
	'../../assets/css/bootstrap.min.css',
	'../../assets/scss/styles.scss', 
	'../../assets/scss/azamara.scss', 
	'../../assets/scss/fonts.scss',
	'./az-destination.component.css',
  '../../assets/cssDali/general.scss'
  ]
})
export class AzDestinationComponent implements OnInit {
  public contenido = this.contenidoService.getContenido("az-destination");
  constructor(private contenidoService: ContenidoService) {}

  ngOnInit() {
  }

}
