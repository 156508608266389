import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './services/login.service';
import { SessionService } from '../session.service';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import * as sha1 from 'js-sha1';
import { environment } from 'src/environments/environment';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css',
    '../../assets/css/bootstrap.min.css',
    '../../assets/scss/styles.scss', 
    '../../assets/scss/login.scss', 
    '../../assets/scss/fonts.scss',
    '../../assets/ema/main.scss', 
    ]
})
export class LoginComponent implements OnInit {

	private customers = firebase.firestore().collection(`${environment.firestorePath}/customers`);	

  constructor(
		public router: Router,
		public loginService: LoginService,
		private session: SessionService,
		private appComponent: AppComponent
	) {
	}
	rol="Minorista";
  	tipo=false;
  	agentes=[ "Minorisdsdsta", "Mayorista"];
	hide = true;
	check=false;
	inactivo = true;
	error="";
	login = {
		correo: "",
		password: ""
	};
	loading = false;
	errorModal = false;
	errorModalPassword = false;

	actualizaLogin = (input) => {
		if(this.login.correo.length > 0 && this.login.password.length > 0){
			this.inactivo = false;
		}
	};

toggleField = (value) => {
	
	let valor= value;
	if(this.check==false){
		this.rol="Minorista";
	} else if(this.check===true){
		this.tipo=false;
		this.rol="Mayorista";
	}
	console.log(this.rol);

	console.log(this.check);
}
verificaSesion = () => {
	if(localStorage.jwt){
		this.router.navigate(["/home"]);
	}
}

ngOnInit() {
	this.verificaSesion();
	this.appComponent.checkBuscador();
	console.log(this.check);
}

clear = () =>{
	this.error = '';
}

closeModalError = () => {
	this.errorModal = false;
	this.errorModalPassword = false;
}
	iniciarSesion = () => {
		var email_reg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
		let self = this;
		if(!this.login.correo){
			return;
		}
		if (email_reg.test(this.login.correo) == false) {
			this.error="Formato de correo no válido. Intenta de nuevo";
			return
		 }
		if(this.rol=="Minorista"){
			this.login.password="password";		
		}else{
			if(!this.login.password){
				self.errorModalPassword = true;
				return;
			}
		}
		this.loading = true;		
		if(this.rol=="Minorista"){
			this.customers.where('correo', '==', this.login.correo).get().then(snapshot => {
					if(snapshot.docs.length == 0){
						self.errorModal = true;
						this.loading = false;
					}
					snapshot.forEach(doc => {
						console.log(doc.id, '=>', doc.data());
						this.loading = false;
						this.session.setUser(this.login.correo);
						this.session.correo = this.login.correo;						
						let customerRef = this.customers.doc(doc.id);
						let updateSingle = customerRef.update({ online: true });
						this.appComponent.checkBuscador();
						this.router.navigate(['']);
					}
				);
			}).catch(err => {
				console.log('Error getting documents', err);
				self.errorModal = true;
				this.loading = false;
			});
		}else{
			this.customers.where('correo', '==', this.login.correo).where('password', '==', sha1(this.login.password) ).get().then(snapshot => {
					if(snapshot.docs.length == 0){
						self.errorModalPassword = true;
						this.loading = false;
					}
					snapshot.forEach(doc => {						
						this.loading = false;
						this.session.setUser(this.login.correo);
						this.session.correo = this.login.correo;
						let customerRef = this.customers.doc(doc.id);
						let updateSingle = customerRef.update({ online: true });
						localStorage.tipo = 'mayorista';
						this.appComponent.checkBuscador();
						this.router.navigate(['']);
					}
				);
			}).catch(err => {
				console.log('Error getting documents', err);
				self.errorModalPassword = true;
				this.loading = false;
			});
		}
	}
};








