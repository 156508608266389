import { Component, OnInit } from '@angular/core';
import { MenuComponent } from '../menu/menu.component';
import { ContenidoService } from '../contenido.service';

@Component({
  selector: 'app-cc-accesories',
  templateUrl: './cc-accesories.component.html',
  styleUrls: [
	'../../assets/css/bootstrap.min.css',
	'../../assets/scss/styles.scss', 
	'../../assets/scss/celebrity.scss', 
	'../../assets/scss/fonts.scss',
	'./cc-accesories.component.css',
  '../../assets/cssDali/general.scss'
  ]
})
export class CcAccesoriesComponent implements OnInit {
  public contenido = this.contenidoService.getContenido("cc-accesories");
  constructor(private contenidoService: ContenidoService) {}

  ngOnInit() {
  }

}
